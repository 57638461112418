<template>
  <div>
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-plataforma-iuea-hide">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Meu perfil</a>
            </li>
          </ul>
          <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
            <li class="fd-app-breadcrumb-item aluno_font_color">
              <a
                :href="'/minhas-plataformas'"
                @click.prevent="$router.push('/minhas-plataformas')"
              >IUEA</a>
            </li>
            <li class="fd-app-breadcrumb-item aluno_font_color active">
              <a href="#">Meu perfil</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
        <section class="fd-app-welcome">
          <h1>
            Meu,
            <span>perfil</span>
          </h1>
          <img :src="require('@/assets/images/form-effect-01.png')">

          <div class="row mt-4">
            
            <div class="col-lg-12 col-sm-12">
              <div class="row">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab
                      title="Configurações"
                      active
                    >
                      <b-card-text>
                        <div class="row">
                          <!-- AVATAR -->
                          <div class="col-lg-6 col-sm-12">
                            <div class="card hovercard">
                              <div
                                class="cardheader"
                                style="background-color: #f5f5f5"
                              />
                              <div
                                class="mt-4 mb-4"
                                :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(imageUsuario ? imageUsuario : fastUsuarioParametro.image) + ')', width: '128px', height: '128px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }"
                              />
                              <div class="pl-4 pr-4">
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                  <!-- upload aqui -->
                                  <input
                                    id="image_perfil"
                                    type="file"
                                    class="custom-file-input"
                                    accept="image/*"
                                    @change="setImagePerfil"
                                  >
                                  <label
                                    class="custom-file-label text-left"
                                    for="image_perfil"
                                  >Trocar imagem de perfil...</label>
                                  <div class="invalid-feedback">
                                    Arquivo inválido
                                  </div>
                                  <modal
                                    name="modalImageCropPerfil"
                                    :shift-y="0.1"
                                    height="auto"
                                    :scrollable="true"
                                    :adaptative="true"
                                    :width="modalWidthSm"
                                  >
                                    <div class="row">
                                      <div class="col-12 pt-4 text-center">
                                        <h4>Recorte a imagem</h4>
                                      </div>
                                      <div
                                        v-if="fastUsuarioParametro.image"
                                        class="col-sm-12 col-md-12 col-lg-12 p-4 text-center"
                                      >
                                        <vue-cropper
                                          ref="cropper"
                                          :src="fastUsuarioParametro.image"
                                          :auto-crop-area="1"
                                          :aspect-ratio="163 / 162"
                                        />
                                        <div class="text-center mt-2">
                                          <span
                                            class="btn btn-primary"
                                            @click.prevent="cropImage()"
                                          >Recortar</span>
                                        </div>
                                      </div>
                                    </div>
                                  </modal>
                                </div>
                                <label class="message-file-label text-left">Dimensões sugeridas: 128 x 128 pixels </label>
                              </div>

                              <div class="info mb-4">
                                <div class="title">
                                  <a href="#">{{ nomeUsuario }} {{ sobrenomeUsuario }}</a>
                                </div>
                                <div class="desc">
                                  {{ emailUsuario }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- PARÂMETROS DO PERFIL -->
                          <div class="col-lg-6 col-sm-12">
                            <transition name="slide-fade">
                              <div
                                v-show="fastPessoa.id_pessoa"
                                class="card hovercard"
                              >
                                <div class="info p-4">
                                  <div>
                                    <h4>Parâmetros do perfil</h4>
                                  </div>
                                  <div class="mt-4 text-left">
                                    URL zoom:
                                    <input
                                      v-model="fastUsuarioParametro.url_zoom"
                                      type="text"
                                      class="form-control"
                                      @keyup="
                                        fastUsuarioParametro.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                  <div
                                    v-if="fastUsuarioParametro.alteracao_pendente"
                                    class="text-center"
                                  >
                                    <button
                                      class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                      @click="alteraUsuarioParametro()"
                                    >
                                      <small>
                                        <i
                                          class="fa fa-floppy-o"
                                          aria-hidden="true"
                                        />
                                        &nbsp;Salvar alterações
                                      </small>
                                    </button>
                                    <button
                                      class="btn btn-secondary btn-sm mt-2 ml-2"
                                      @click.prevent="getFastUsuarioParametro()"
                                    >
                                      <small>
                                        <i
                                          class="fa fa-ban"
                                          aria-hidden="true"
                                        />
                                        &nbsp;Cancelar
                                      </small>
                                    </button>
                                  </div>
                                  <div
                                    v-if="fastPlataformaUsuario.alteracao_pendente"
                                    class="text-center"
                                  >
                                    <button
                                      class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                      @click="alteraPlataformaUsuario()"
                                    >
                                      <small>
                                        <i
                                          class="fa fa-floppy-o"
                                          aria-hidden="true"
                                        />
                                        &nbsp;Salvar alterações
                                      </small>
                                    </button>
                                    <button
                                      class="btn btn-secondary btn-sm mt-2 ml-2"
                                      @click.prevent="getFastUsuarioParametro()"
                                    >
                                      <small>
                                        <i
                                          class="fa fa-ban"
                                          aria-hidden="true"
                                        />
                                        &nbsp;Cancelar
                                      </small>
                                    </button>
                                  </div>
                                </div>                    
                              </div>
                            </transition>
                          </div>
                          <!-- PESSOA -->
                          <div class="col-12 mt-4">
                            <transition name="slide-fade">
                              <div
                                v-show="fastPessoa.id_pessoa"
                                class="card hovercard"
                              >
                                <div class="info p-4">
                                  <div>
                                    <h4>Informações pessoais</h4>
                                  </div>
                                  <div class="mt-4 text-left">
                                    Nome:
                                    <input
                                      v-if="!fastPessoa.possui_matricula"
                                      v-model="fastPessoa.nome_razao"
                                      type="text"
                                      class="form-control"
                                      @keyup="
                                        fastPessoa.alteracao_pendente = true
                                      "
                                    >
                                    <input v-else type="text" class="form-control" :value="fastPessoa.nome_razao" readonly>
                                  </div>
                                  <div class="mt-4 text-left">
                                    Sobrenome:
                                    <input
                                      v-if="!fastPessoa.possui_matricula"
                                      v-model="fastPessoa.sobrenome_fantasia"
                                      type="text"
                                      class="form-control"
                                      @keyup="
                                        fastPessoa.alteracao_pendente = true
                                      "
                                    >
                                    <input v-else type="text" class="form-control" :value="fastPessoa.sobrenome_fantasia" readonly>
                                  </div>
                                  <div class="mt-4 text-left">
                                    Data de nascimento:
                                    <input
                                      v-if="!fastPessoa.possui_matricula"
                                      v-model="fastPessoa.datanascimento_abertura"
                                      type="date"
                                      class="form-control"
                                      @change="fastPessoa.alteracao_pendente = true"
                                    >
                                    <input v-else type="text" class="form-control" :value="fastPessoa.datanascimento_abertura.split('-')[2] + '/' + fastPessoa.datanascimento_abertura.split('-')[1] + '/' + fastPessoa.datanascimento_abertura.split('-')[0]" readonly>
                                  </div>
                                  <div class="mt-4 text-left">
                                    CPF:
                                    <input
                                      v-if="!fastPessoa.possui_matricula"
                                      v-model="fastPessoa.cpf_cnpj"
                                      v-mask="['###.###.###-##', '##.###.###/####-##']"
                                      type="text"
                                      class="form-control"
                                      @keyup="fastPessoa.alteracao_pendente = true"
                                    >
                                    <input v-else type="text" class="form-control" :value="fastPessoa.cpf_cnpj" readonly>
                                  </div>

                                  <div class="mt-4 text-left">
                                    Sexo:
                                    <select
                                      v-if="!fastPessoa.possui_matricula"
                                      v-model="fastPessoa.sexo"
                                      class="form-control"
                                      @change="fastPessoa.alteracao_pendente = true"
                                    >
                                      <option value="M">
                                        Masculino
                                      </option>
                                      <option value="F">
                                        Feminino
                                      </option>
                                    </select>
                                    <div v-else>
                                      <input
                                        v-if="fastPessoa.sexo == 'M'"
                                        type="text"
                                        class="form-control"
                                        value="Masculino"
                                        readonly
                                      >
                                      <input
                                        v-else-if="fastPessoa.sexo == 'F'"
                                        type="text"
                                        class="form-control"
                                        value="Feminino"
                                        readonly
                                      >
                                    </div>
                                  </div>

                                  <div class="mt-4 text-left">
                                    Telefone:
                                    <input
                                      v-if="!fastPessoa.possui_matricula"
                                      v-model="fastPessoa.telefone"
                                      v-mask="['(##) ####-####', '(##) #####-####']"
                                      type="text"
                                      class="form-control"
                                      @keyup="fastPessoa.alteracao_pendente = true"
                                    >
                                    <input v-else type="text" class="form-control" :value="fastPessoa.telefone" readonly>
                                  </div>

                                  <div class="mt-4">
                                    <h4>Endereço</h4>
                                  </div>
                                  <div class="mt-4 text-left">
                                    CEP:
                                    <input
                                      v-if="!fastPessoa.possui_matricula"
                                      v-model="fastPessoa.cep"
                                      v-mask="'#####-###'"
                                      type="text"
                                      class="form-control"
                                      @keyup="fastPessoa.alteracao_pendente = true; buscaCEPPessoa()"
                                    >
                                    <input v-else type="text" class="form-control" :value="fastPessoa.cep" readonly>
                                  </div>
                                  <div class="mt-4 text-left">
                                    Endereço:
                                    <input
                                      v-if="!fastPessoa.possui_matricula"
                                      v-model="fastPessoa.logradouro"
                                      type="text"
                                      class="form-control"
                                      maxlength="100"
                                      @keyup="fastPessoa.alteracao_pendente = true"
                                    >
                                    <input v-else type="text" class="form-control" :value="fastPessoa.logradouro" readonly>
                                  </div>
                                  <div class="mt-4 text-left">
                                    Número:
                                    <input
                                      v-if="!fastPessoa.possui_matricula"
                                      v-model="fastPessoa.numero"
                                      type="text"
                                      class="form-control"
                                      maxlength="10"
                                      @keyup="fastPessoa.alteracao_pendente = true"
                                    >
                                    <input v-else type="text" class="form-control" :value="fastPessoa.numero" readonly>
                                  </div>
                                  <div class="mt-4 text-left">
                                    Complemento:
                                    <input
                                      v-if="!fastPessoa.possui_matricula"
                                      v-model="fastPessoa.complemento"
                                      type="text"
                                      class="form-control"
                                      @keyup="fastPessoa.alteracao_pendente = true"
                                    >
                                    <input v-else type="text" class="form-control" :value="fastPessoa.complemento" readonly>
                                  </div>
                                  <div class="mt-4 text-left">
                                    Bairro:
                                    <input
                                      v-if="!fastPessoa.possui_matricula"
                                      v-model="fastPessoa.bairro"
                                      type="text"
                                      class="form-control"
                                      @keyup="fastPessoa.alteracao_pendente = true"
                                    >
                                    <input v-else type="text" class="form-control" :value="fastPessoa.bairro" readonly>
                                  </div>
                                  <div class="mt-4 text-left">
                                    Cidade:
                                    <input
                                      v-if="!fastPessoa.possui_matricula"
                                      v-model="fastPessoa.cidade"
                                      type="text"
                                      class="form-control"
                                      @keyup="fastPessoa.alteracao_pendente = true"
                                    >
                                    <input v-else type="text" class="form-control" :value="fastPessoa.cidade" readonly>
                                  </div>
                                  <div class="mt-4 text-left">
                                    Estado:
                                    <input
                                      v-if="!fastPessoa.possui_matricula"
                                      v-model="fastPessoa.uf"
                                      type="text"
                                      class="form-control"
                                      maxlength="2"
                                      @keyup="fastPessoa.alteracao_pendente = true"
                                    >
                                    <input v-else type="text" class="form-control" :value="fastPessoa.uf" readonly>
                                  </div>

                                  <div
                                    v-if="fastPessoa.alteracao_pendente"
                                    class="text-center"
                                  >
                                    <button
                                      class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                      @click="alteraPlataformaPessoa()"
                                    >
                                      <small>
                                        <i
                                          class="fa fa-floppy-o"
                                          aria-hidden="true"
                                        />
                                        &nbsp;Salvar alterações
                                      </small>
                                    </button>
                                    <button
                                      class="btn btn-secondary btn-sm mt-2 ml-2"
                                      @click.prevent="buscaInfoPessoa()"
                                    >
                                      <small>
                                        <i
                                          class="fa fa-ban"
                                          aria-hidden="true"
                                        />
                                        &nbsp;Cancelar
                                      </small>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </transition>
                          </div>
                          <!-- FINANCEIRO -->
                          <div v-if="$store.state.fastPermissoes.aluno != 'S'" class="col-12 mt-4">
                            <transition name="slide-fade">
                              <div
                                v-show="fastPessoa.id_pessoa"
                                class="card hovercard"
                              >
                                <div class="info p-4">
                                  <div>
                                    <h4>Financeiro</h4>
                                  </div>
                                  <div class="mt-4 text-left">
                                    Banco:
                                    <input
                                      v-model="fastPessoaFinanceiro.banco"
                                      type="text"
                                      class="form-control"
                                      @keyup="
                                        fastPessoaFinanceiro.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                  <div class="mt-4 text-left">
                                    Código do banco:
                                    <input
                                      v-model="fastPessoaFinanceiro.codigo_banco"
                                      type="text"
                                      class="form-control"
                                      @keyup="
                                        fastPessoaFinanceiro.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                  <div class="mt-4 text-left">
                                    Identificador de Conta Gerencianet:
                                    <input
                                      v-model="fastPessoaFinanceiro.payee_code_gerencianet"
                                      type="text"
                                      class="form-control"
                                      @keyup="
                                        fastPessoaFinanceiro.alteracao_pendente = true
                                      "
                                    >
                                  </div>
                                  <div
                                    v-if="fastPessoaFinanceiro.alteracao_pendente"
                                    class="text-center"
                                  >
                                    <button
                                      class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                                      @click="alteraPessoaFinanceiro()"
                                    >
                                      <small>
                                        <i
                                          class="fa fa-floppy-o"
                                          aria-hidden="true"
                                        />
                                        &nbsp;Salvar alterações
                                      </small>
                                    </button>
                                    <button
                                      class="btn btn-secondary btn-sm mt-2 ml-2"
                                      @click.prevent="getPessoaFinanceiro($route.params.id_plataforma)"
                                    >
                                      <small>
                                        <i
                                          class="fa fa-ban"
                                          aria-hidden="true"
                                        />
                                        &nbsp;Cancelar
                                      </small>
                                    </button>
                                  </div>
                                </div>                    
                              </div>
                            </transition>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
                
                
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <a
              class="btn btn-red-hollow"
              :href="'/plataforma/' + $route.params.id_plataforma"
            >
              <b-icon-arrow-return-left /> Voltar
            </a>
          </div>
        </section>
      </div>
    </div>
    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "HomeInternoMeuPerfil",
  components: {
    VueCropper
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      nomeUsuario: this.retornaSessao(settings.fastSessaoUsuario).First_name,
      sobrenomeUsuario: this.retornaSessao(settings.fastSessaoUsuario)
        .Last_name,
      imageUsuario: this.retornaSessao(settings.fastSessaoUsuario).Image,
      emailUsuario: this.retornaSessao(settings.fastSessaoUsuario).Email,
      nome_plataforma: "",
      n_cursos: "",
      n_cursos_publicados: "",
      n_professores: "",
      banner_plataforma: "",
      logo_plataforma: "",
      // Parametro
      fastUsuarioParametro: {
        id_usuario: "",
        image: "",
        url_zoom: "",
      },
      fastPlataformaUsuario: {
        nome_usuario:
          this.retornaSessao(settings.fastSessaoUsuario).First_name +
          " " +
          this.retornaSessao(settings.fastSessaoUsuario).Last_name,
        alteracao_pendente: false,
      },
      // Pessoa
      fastPessoa: {
        id_pessoa: 0,
        nome_razao: "",
        sobrenome_fantasia: "",
        cpf_cnpj: "",
        datanascimento_abertura: null,
        sexo: "",
        uf: "",
        cep: "",
        logradouro: "",
        bairro: "",
        cidade: "",
        complemento: "",
        numero: "",
        telefone: "",
        payee_code_gerencianet: "",
        alteracao_pendente: false,
        possui_matricula: true
      },
      fastPessoaFinanceiro: {
        id_pessoa: 0,
        id_plataforma: 0,
        banco: "",
        codigo_banco: "",
        payee_code_gerencianet: "",
        loading: false,
        alteracao_pendente: false
      },
      fastPessoaComissao: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: false
      },
      fastFiltro: {
        plataforma: {
          lista: [],
          escolhida: ""
        },
        anoVencimento: {
          lista: ['Todos', (new Date().getFullYear()) - 1, (new Date().getFullYear()), (new Date().getFullYear()) + 1],
          escolhido: 'Todos'
        },
        mesVencimento: {
          lista: ['Todos', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
          escolhido: 0
        },
        anoRegistro: {
          lista: ['Todos', (new Date().getFullYear()) - 1, (new Date().getFullYear()), (new Date().getFullYear()) + 1],
          escolhido: 'Todos'
        },
        mesRegistro: {
          lista: ['Todos', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
          escolhido: 0
        },
        diaRegistro: {
          lista: [],
          escolhido: ""
        },
        diaVencimento: {
          lista: [],
          escolhido: ""
        },
        status: {
          escolhido: ""
        },
        data_alterada: false
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getDependenciasVisaoAluno(this.$route.params.id_plataforma)
        .then(() => {
          // Se foi personalizado aplica cor nos textos
          if (this.$store.state.fastPersonalizacao.aluno_font_color) {
            Array.from(document.querySelectorAll(".aluno_font_color")).forEach((e) => (e.style.color = this.$store.state.fastPersonalizacao.aluno_font_color));
          }
          // Se foi personalizado aplica cor nos textos dos menus
          if (this.$store.state.fastPersonalizacao.menu_font_color) {
            Array.from(document.querySelectorAll(".menu_font_color")).forEach((e) => (e.style.color = this.$store.state.fastPersonalizacao.menu_font_color));
          }
          this.getFastUsuarioParametro();
          this.buscaInfoPessoa();
          this.getPessoaFinanceiro(this.$route.params.id_plataforma)

          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });

    }    
  },

  methods: {
    async getFastUsuarioParametro() {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_usuario_parametro/lista",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastUsuarioParametro = obj[0];
        } else {
          this.fastUsuarioParametro = {
            id_usuario: "",
            image: "",
            url_zoom: "",
            alteracao_pendente: false,
          };
        }
        // Informa ao component pai para interromper o loading
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Busca cep api correio
    async buscaCEPPessoa(){
      if (this.fastPessoa.cep.length == 9) {
        this.buscaCEPCorreio(this.fastPessoa.cep).then(e => {
          if (e.cep){
            this.fastPessoa.cep = e.cep;
            this.fastPessoa.logradouro = e.logradouro;
            this.fastPessoa.bairro = e.bairro;
            this.fastPessoa.cidade = e.cidade;
            this.fastPessoa.uf = e.uf;
          } else {
            this.exibeToasty("CEP não encontrado", "error")
          }
        }).catch(e => this.exibeToasty(e, "error"))
      }      
    },
    async alteraUsuarioParametro() {
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_usuario_parametro/insere",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(this.fastUsuarioParametro)
          )
        );
        let json = await resp.json();
        this.getFastUsuarioParametro();
        if (this.fastUsuarioParametro.image) this.buscaInfoUsuario();
        this.$store.state.fastCarregando = false;
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Perfil alterado com sucesso",
        });
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    async buscaInfoUsuario() {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma_usuario/busca_usuario",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        if (!json.error) {
          // Armazena informações do usuário na sessão
          sessionStorage.setItem(settings.fastSessaoUsuario, json);
          this.imageUsuario = this.retornaSessao(
            settings.fastSessaoUsuario
          ).Image;
          this.nomeUsuario = this.retornaSessao(
            settings.fastSessaoUsuario
          ).First_name;
          this.sobrenomeUsuario = this.retornaSessao(
            settings.fastSessaoUsuario
          ).Last_name;
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async buscaInfoPessoa() {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma_usuario/busca_pessoa_plataforma?id_plataforma=" + this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = JSON.parse(json);
        if (!obj.error) {
          if (obj.datanascimento_abertura)
            obj.datanascimento_abertura = obj.datanascimento_abertura.split(
              "T"
            )[0];
          else obj.datanascimento_abertura = "";
          if (!obj.nome_razao) obj.nome_razao = "";
          if (!obj.sobrenome_fantasia) obj.sobrenome_fantasia = "";
          if (!obj.cpf_cnpj) obj.cpf_cnpj = "";
          if (!obj.cep) obj.cep = "";
          if (!obj.cidade) obj.cidade = "";
          if (!obj.complemento) obj.complemento = "";
          if (!obj.logradouro) obj.logradouro = "";
          if (!obj.numero) obj.numero = "";
          if (!obj.sexo) obj.sexo = "";
          if (!obj.telefone) obj.telefone = "";
          if (!obj.uf) obj.uf = "";
          if (!obj.bairro) obj.bairro = "";
          if (!obj.payee_code_gerencianet) obj.payee_code_gerencianet = "";
          obj.possui_matricula = true

          this.fastPessoa = obj;
          if (this.fastPessoa.id_pessoa) this.getFastMatriculaAluno(this.$route.params.id_plataforma, this.fastPessoa.id_pessoa)
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    //imageCropper
    cropImage(tipo) {
      this.fastUsuarioParametro.image = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL();
      this.alteraUsuarioParametro();
      this.hideModal("modalImageCropPerfil");
    },
    setImagePerfil(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        this.$toast.open({
          message: "Selecione um arquivo de imagem",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        return;
      }

      if (file.size / 1024 > 5120) {
        this.$toast.open({
          message: "O limite de tamanho do arquivo é 5MB",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
        return;
      }

      this.showModal("modalImageCropPerfil");

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.fastUsuarioParametro.image = event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        this.$toast.open({
          message: "Recurso não suportado pelo dispositivo",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "bottom-right",
        });
      }
    },
    async alteraPlataformaUsuario() {
      let erros = [];
      if (!this.fastPlataformaUsuario.nome_usuario.length)
        erros.push("O nome não pode ficar em branco");
      if (this.fastPlataformaUsuario.nome_usuario.split(" ").length < 2)
        erros.push("É necessário inserir nome e sobrenome");

      if (erros.length) {
        erros.forEach((e) => {
          this.$toast.open({
            message: e,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "bottom-right",
          });
        });
      } else {
        this.$store.state.fastCarregando = true;
        const fast_plataforma_usuario = {
          nome_usuario: this.fastPlataformaUsuario.nome_usuario,
        };
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_plataforma_usuario/atualiza_nome",
            this.fastAjaxOptions(
              "POST",
              JSON.stringify(fast_plataforma_usuario)
            )
          );
          let json = await resp.json();
          console.log(json);
          this.fastPlataformaUsuario = {
            nome_usuario: this.fastPlataformaUsuario.nome_usuario,
            alteracao_pendente: false,
          };
          this.buscaInfoUsuario();
          this.getFastUsuarioParametro();
          this.$store.state.fastCarregando = false;
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Perfil alterado com sucesso",
          });
        } catch (e) {
          this.$store.state.fastCarregando = false;
          console.log("Erro", e);
        }
      }
    },
    async alteraPlataformaPessoa() {
      this.$store.state.fastCarregando = true;
      const ModelPessoa = {
        fast_pessoa: {         
          nome_razao: this.fastPessoa.nome_razao, 
          sobrenome_fantasia: this.fastPessoa.sobrenome_fantasia, 
          cpf_cnpj: this.fastPessoa.cpf_cnpj,
          datanascimento_abertura: this.fastPessoa.datanascimento_abertura,
          sexo: this.fastPessoa.sexo,
          id_plataforma: this.$route.params.id_plataforma,
          payee_code_gerencianet: this.fastPessoa.payee_code_gerencianet
        },
        fast_pessoa_endereco: {
          id_pessoa: this.fastPessoa.id_pessoa,
          uf: this.fastPessoa.uf,
          cep: this.fastPessoa.cep,
          logradouro: this.fastPessoa.logradouro,
          bairro: this.fastPessoa.bairro,
          cidade: this.fastPessoa.cidade,
          complemento: this.fastPessoa.complemento,
          numero: this.fastPessoa.numero,
        },
        fast_pessoa_telefone: {
          telefone: this.fastPessoa.telefone,
        },
        id_plataforma: this.$route.params.id_plataforma
      };
      
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_usuario/atualiza_pessoa",
          this.fastAjaxOptions("POST", JSON.stringify(ModelPessoa))
        );
        let json = await resp.json();
        this.fastPessoa.alteracao_pendente = false;
        console.log("alteraPlataformaPessoa", json);
        let obj = JSON.parse(json);

        if (!obj.error) {
          if (obj.datanascimento_abertura)
            obj.datanascimento_abertura = obj.datanascimento_abertura.split(
              "T"
            )[0];
          else obj.datanascimento_abertura = "";
          if (!obj.first_name) obj.First_name = "";
          if (!obj.last_name) obj.Last_name = "";
          if (!obj.cpf_cnpj) obj.cpf_cnpj = "";
          if (!obj.cep) obj.cep = "";
          if (!obj.cidade) obj.cidade = "";
          if (!obj.complemento) obj.complemento = "";
          if (!obj.logradouro) obj.logradouro = "";
          if (!obj.numero) obj.numero = "";
          if (!obj.sexo) obj.sexo = "";
          if (!obj.telefone) obj.telefone = "";
          if (!obj.uf) obj.uf = "";
          if (!obj.bairro) obj.bairro = "";
          if (!obj.payee_code_gerencianet) obj.payee_code_gerencianet = "";
          obj.possui_matricula = true
          
          this.fastPessoa = obj;
        }
        this.$store.state.fastCarregando = false;
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Perfil alterado com sucesso",
        });
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    // Matrícula
    async getFastMatriculaAluno(id_plataforma, id_pessoa_aluno) {
      this.promiseGetFastApi(
        "api/fast_secretaria_matricula_aluno/lista",
        "id_plataforma=" +
          id_plataforma +
          "&id_programa=0&id_pessoa_aluno=" +
          id_pessoa_aluno
      )
        .then((obj) => {
          if (!obj.length) {
            this.fastPessoa.possui_matricula = false
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // Financeiro
    async getPessoaFinanceiro(id_plataforma){
      this.fastPessoaFinanceiro.alteracao_pendente = false
      this.fastPessoaFinanceiro.loading = true
      this.promiseGetFastApi("api/fast_plataforma_usuario/lista_pessoa_financeiro", `id_plataforma=${id_plataforma}`)
        .then(res => {
          console.log(res)
          // Verifica JSON
          const [err, obj] = this.safeJsonParse(res);
          if (err) {
            this.exibeToasty("Falha ao interpretar json", "error") 
            console.log('Falha ao interpretar json: ' + err.message)
          } else {
            if (!obj.error) {
              this.fastPessoaFinanceiro.id_pessoa = obj.id_pessoa
              this.fastPessoaFinanceiro.id_plataforma = obj.id_plataforma
              this.fastPessoaFinanceiro.banco = obj.banco
              this.fastPessoaFinanceiro.codigo_banco = obj.codigo_banco
              this.fastPessoaFinanceiro.payee_code_gerencianet = obj.payee_code_gerencianet
            } else {
              this.exibeToasty("Erro ao recuperar pessoa", "error") 
            }
          }
          this.fastPessoaFinanceiro.loading = false
        })
        .catch(e => {
          this.exibeToasty(e, "error")  
          this.fastPessoaFinanceiro.loading = false
        })
    },
    async alteraPessoaFinanceiro(){
      this.$store.state.fastCarregando = true
      if (this.fastPessoaFinanceiro.banco) this.fastPessoaFinanceiro.banco = this.fastPessoaFinanceiro.banco.trim()
      if (this.fastPessoaFinanceiro.codigo_banco) this.fastPessoaFinanceiro.codigo_banco = this.fastPessoaFinanceiro.codigo_banco.trim()
      if (this.fastPessoaFinanceiro.payee_code_gerencianet) this.fastPessoaFinanceiro.payee_code_gerencianet = this.fastPessoaFinanceiro.payee_code_gerencianet.trim()
      this.promisePostFastApi(this.fastPessoaFinanceiro, "api/fast_plataforma_usuario/atualiza_pessoa_financeiro")
      .then(res => {                
        // Verifica JSON
        const [err, obj] = this.safeJsonParse(res);
        if (err) {
          this.exibeToasty("Falha ao interpretar json", "error") 
          console.log('Falha ao interpretar json: ' + err.message)
        } else {
          if (!obj.error) {
            this.fastPessoaFinanceiro.id_pessoa = obj.id_pessoa
            this.fastPessoaFinanceiro.id_plataforma = obj.id_plataforma
            this.fastPessoaFinanceiro.banco = obj.banco
            this.fastPessoaFinanceiro.codigo_banco = obj.codigo_banco
            this.fastPessoaFinanceiro.payee_code_gerencianet = obj.payee_code_gerencianet

            this.fastPessoaFinanceiro.alteracao_pendente = false
          } else {
            this.exibeToasty("Erro ao salvar dados", "error") 
          }
        }
        this.$store.state.fastCarregando = false
      })
      .catch((e) => {                
        this.exibeToasty(`${e}`, "error")
        this.$store.state.fastCarregando = false
      }); 
    },
    // Comissão
    async getInvoiceComissao(id_plataforma){
      this.fastPessoaComissao.loading = true
      this.fastPessoaComissao.total = []
      this.fastPessoaComissao.filtro = []
      this.promiseGetFastApi("api/fast_tesouraria_invoice_schema_comissao/lista_usuario", `id_plataforma=${id_plataforma}`)
      .then((res) => {
        console.log("getInvoiceComissao", res)
        if (res.length) {
          this.fastPessoaComissao.total = res
          this.fastPessoaComissao.filtro = res
        } 
        this.fastPessoaComissao.loading = false
      })
      .catch(e => {
        this.exibeToasty(e, "error")
        this.fastPessoaComissao.loading = false
      })
    },
    alteraDataFiltro(){
      // int id_plataforma, string ano_vencimento, string mes_vencimento, string ano_registro, string mes_registro  
      let mesVencimento = ""
      let anoVencimento = ""

      if (this.fastFiltro.anoVencimento.escolhido != "Todos") anoVencimento = this.fastFiltro.anoVencimento.escolhido
      if (this.fastFiltro.mesVencimento.escolhido) {
        mesVencimento = this.fastFiltro.mesVencimento.escolhido
        mesVencimento = mesVencimento < 10 ? '0' + (mesVencimento).toString() : mes
      }

      let mesRegistro = ""
      let anoRegistro = ""

      if (this.fastFiltro.anoRegistro.escolhido != "Todos") anoRegistro = this.fastFiltro.anoRegistro.escolhido
      if (this.fastFiltro.mesRegistro.escolhido) {
        mesRegistro = this.fastFiltro.mesRegistro.escolhido
        mesRegistro = mesRegistro < 10 ? '0' + (mesRegistro).toString() : mes
      }

      this.fastPessoaComissao.filtro = this.fastPessoaComissao.total.filter(e => {
        return this.executaFiltro(anoVencimento, mesVencimento, anoRegistro, mesRegistro, this.fastFiltro.status.escolhido, e)
      })      
    },
    executaFiltro(anoVencimento, mesVencimento, anoRegistro, mesRegistro, status, e){
      let retorno = true

      if (anoVencimento && e.data_vencimento) {
        if (e.data_vencimento.split("-")[0] == anoVencimento) retorno = true
        else
        retorno = false
      } 

      if (mesVencimento && e.data_vencimento && retorno) {
        if (parseInt(e.data_vencimento.split("-")[1]) == mesVencimento) retorno = true
        else
        retorno = false
      } 

      if (anoRegistro && e.data_registro && retorno) {
        if (parseInt(e.data_registro.split("-")[0]) == anoRegistro) retorno = true
        else
        retorno = false
      } 

      if (mesRegistro && e.data_registro && retorno) {
        if (parseInt(e.data_registro.split("-")[1]) == mesRegistro) retorno = true
        else
        retorno = false
      } 

      if (this.fastFiltro.status.escolhido && retorno) {
        switch (this.fastFiltro.status.escolhido) {
          case 'P':
            // Pago
            if (e.pago && !e.cancelado_parcela) retorno = true
            else
            retorno = false
            break;
          case 'A':
            // Pendente
            if (!e.pago && !e.cancelado_parcela && !this.verificaVencimento(e.data_vencimento)) retorno = true
            else
            retorno = false
            break;
          case 'V':
            // Vencido
            if (!e.pago && !e.cancelado_parcela && this.verificaVencimento(e.data_vencimento)) retorno = true
            else
            retorno = false
            break;
          case 'C':
            // Cancelado
            if (e.cancelado_parcela) retorno = true
            else
            retorno = false
            break;
        
          default:
            break;
        }
      } 

      return retorno
    },
    exibeTotalPeriodo(){
      let total = 0.00
      this.fastPessoaComissao.filtro.forEach(e => {
        if (!e.cancelado_parcela){
          total += e.valor_recebido
        }        
      })
      return this.formataPreco(total)
    },
    exibeRecebidoPeriodo(){
      let total = 0.00
      this.fastPessoaComissao.filtro.forEach(e => {
        if (e.pago && !e.cancelado_parcela) {
          total += e.valor_recebido
        }        
      })
      return this.formataPreco(total)
    },
    exibeReceberPeriodo(){
      let total = 0.00
      this.fastPessoaComissao.filtro.forEach(e => {
        if (!e.pago && !e.cancelado_parcela) {
          total += e.valor_recebido
        }        
      })
      return this.formataPreco(total)
    },
    verificaVencimento(dataVencimento){
      let dataAtual = this.$store.state.fastDataAtualServidor.toLocaleDateString('pt-br').split( '/' ).reverse( ).join( '-' )   
      if (dataVencimento) {
        dataVencimento = new Date(dataVencimento.split("T")[0]).toISOString().slice(0,10);   
      }
      if (dataAtual > dataVencimento) {
        return true
      }
      else {
        return false
      }
      
    },
  },
};
</script>


<style>
.message-file-label{
  display: flex;
  align-items: left;
  font-size: 12px;
  color:#495057;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}                                
</style>



<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}
@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

/*
.card {
  padding-top: 20px;
  margin: 10px 0 20px 0;
  background-color: rgba(214, 224, 226, 0.2);
  border-top-width: 0;
  border-bottom-width: 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.card .card-heading {
  padding: 0 20px;
  margin: 0;
}

.card .card-heading.simple {
  font-size: 20px;
  font-weight: 300;
  color: #777;
  border-bottom: 1px solid #e5e5e5;
}

.card .card-heading.image img {
  display: inline-block;
  width: 46px;
  height: 46px;
  margin-right: 15px;
  vertical-align: top;
  border: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.card .card-heading.image .card-heading-header {
  display: inline-block;
  vertical-align: top;
}

.card .card-heading.image .card-heading-header h3 {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  color: #262626;
}

.card .card-heading.image .card-heading-header span {
  font-size: 12px;
  color: #999999;
}

.card .card-body {
  padding: 0 20px;
  margin-top: 20px;
}

.card .card-media {
  padding: 0 20px;
  margin: 0 -14px;
}

.card .card-media img {
  max-width: 100%;
  max-height: 100%;
}

.card .card-actions {
  min-height: 30px;
  padding: 0 20px 20px 20px;
  margin: 20px 0 0 0;
}

.card .card-comments {
  padding: 20px;
  margin: 0;
  background-color: #f8f8f8;
}

.card .card-comments .comments-collapse-toggle {
  padding: 0;
  margin: 0 20px 12px 20px;
}

.card .card-comments .comments-collapse-toggle a,
.card .card-comments .comments-collapse-toggle span {
  padding-right: 5px;
  overflow: hidden;
  font-size: 12px;
  color: #999;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-comments .media-heading {
  font-size: 13px;
  font-weight: bold;
}

.card.people {
  position: relative;
  display: inline-block;
  width: 170px;
  height: 300px;
  padding-top: 0;
  margin-left: 20px;
  overflow: hidden;
  vertical-align: top;
}

.card.people:first-child {
  margin-left: 0;
}

.card.people .card-top {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 170px;
  height: 150px;
  background-color: #ffffff;
}

.card.people .card-top.green {
  background-color: #53a93f;
}

.card.people .card-top.blue {
  background-color: #427fed;
}

.card.people .card-info {
  position: absolute;
  top: 150px;
  display: inline-block;
  width: 100%;
  height: 101px;
  overflow: hidden;
  background: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.card.people .card-info .title {
  display: block;
  margin: 8px 14px 0 14px;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  color: #404040;
}

.card.people .card-info .desc {
  display: block;
  margin: 8px 14px 0 14px;
  overflow: hidden;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
  text-overflow: ellipsis;
}

.card.people .card-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  line-height: 29px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.card.hovercard {
  position: relative;
  padding-top: 0;
  overflow: hidden;
  text-align: center;
  background-color: rgba(214, 224, 226, 0.2);
}

.card.hovercard .cardheader {
  background-size: cover;
  height: 135px;
}

.card.hovercard .avatar {
  position: relative;
  top: -50px;
  margin-bottom: -50px;
}

.card.hovercard .avatar img {
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.5);
}

.card.hovercard .info {
  padding: 4px 8px 10px;
}

.card.hovercard .info .title {
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 1;
  color: #262626;
  vertical-align: middle;
}

.card.hovercard .info .desc {
  overflow: hidden;
  font-size: 12px;
  line-height: 20px;
  color: #737373;
  text-overflow: ellipsis;
}

.card.hovercard .bottom {
  padding: 0 20px;
  margin-bottom: 17px;
}

}*/

</style>
